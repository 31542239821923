
/* 
  Created by Hoàng Đức Thuận at 06-28-2022 15:42:22
  Module login
*/
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { ROLL_MANAGER_PATH } from 'constants/path';
import { lazy } from 'react';
import { IRoute } from 'types/Route';

const RollManager = lazy(
    () => import('modules/roll/roll.screen')
);

const ContentLayout = lazy(
    () => import('../../libraries/layouts/content.layout')
);

const RollManagerRoute: IRoute = {
    path: ROLL_MANAGER_PATH,
    name: 'roll.menu',
    icon: <DeploymentUnitOutlined />,
    component: ContentLayout,
    routes: [
        {
            path: ROLL_MANAGER_PATH,
            name: 'roll.menu',
            component: RollManager,
            exact: true
        },
    ]
};

export default RollManagerRoute;

