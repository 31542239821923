import { ToastProvider } from 'hooks/useToast/handling';
import BaseConfirmModal from 'libraries/modal/BaseConfirmModal';
import { Fragment, default as React } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { setI18nConfig } from 'res/languages';
import RouteConfigs from 'routing/config.route';
import MasterRoutes from 'routing/master.route';

setI18nConfig();

const App: React.FC<any> = () => {
    return (
        <Fragment>
            <ToastProvider>
                {/* @ts-ignore */}
                <Router>
                    <MasterRoutes routes={RouteConfigs} />
                </Router>
                <BaseConfirmModal />

            </ToastProvider>
        </Fragment>
    );
};

export default App;
