export default {
    ...require('./vi.shared.json'),
    ...require('../../modules/product/languages/vi.manageGarage.json'),
    ...require('../../modules/auth/res/translations/vi.auth.json'),
    ...require('../../modules/productEntity/languages/vi.label-manager.json'),
    ...require('../../modules/roll/languages/vi.roll-manager.json'),
    
};


